import Vue from 'vue';
import Router from 'vue-router';
import LeadsModule from '@/modules/leads/index.module'
import SurveyModule from '@/modules/survey/index.module'
import Container from 'typedi'

Vue.use(Router)

export default new Router({
    mode: 'history',
    routes: [
        {
            path: '/idp-return',
            alias: ['/dev/idp-return'],
            name: 'registration.idp-return.root',
            meta: {
                title: 'IDP Return',
                layout: 'enduser',
                requireAuth: false
            },
            component: () => import('@/modules/registration/views/login/idp-return.vue')
        },
        {
            path: '/:event_identifier/mcpro-return',
            alias: ['/dev/:event_identifier/mcpro-return', '/:event_identifier/mcpro-return'],
            name: 'registration.mcpro-return.root',
            meta: {
                title: 'Mc Pro Return',
                layout: 'enduser',
                requireAuth: false
            },
            component: () => import('@/modules/registration/views/login/mcpro-return.vue')
        },
        {
            path: '/:event_identifier/mctrade-return',
            alias: ['/dev/:event_identifier/mctrade-return', '/:event_identifier/mctrade-return'],
            name: 'registration.mctrade-return.root',
            meta: {
                title: 'Mc Trade Return',
                layout: 'enduser',
                requireAuth: false
            },
            component: () => import('@/modules/registration/views/login/mctrade-return.vue')
        },
        {
            path: '/idp-choose',
            alias: ['/dev/idp-choose'],
            name: 'registration.idp-choose.root',
            meta: {
                title: 'IDP Choose',
                layout: 'enduser',
                requireAuth: false
            },
            component: () => import('@/modules/registration/views/login/idp-choose.vue')
        },
        {
            path: '/:event_identifier',
            alias: ['/dev/:event_identifier', '/:event_identifier/login'],
            name: 'registration.login.root',
            meta: {
                title: 'Login',
                layout: 'enduser',
                requireAuth: false
            },
            component: () => import('@/modules/registration/views/login/login.vue')
        },
        {
            path: '/:event_identifier/group',
            alias: ['/dev/:event_identifier', '/:event_identifier/login'],
            name: 'registration.login.root',
            meta: {
                title: 'Login',
                layout: 'enduser',
                requireAuth: false
            },
            component: () => import('@/modules/registration/views/login/login.vue')
        },
        {
            path: '/:event_identifier/exhibitor',
            alias: ['/dev/:event_identifier/exhibitior', '/:event_identifier/exhibitior'],
            name: 'registration.login.exhibitor',
            meta: {
                title: 'Login',
                layout: 'enduser',
                requireAuth: false
            },
            component: () => import('@/modules/registration/views/login/login.vue')
        },
        {
            path: '/:event_identifier/participant-cancel/:participant_cancel_auth_token',
            alias: ['/dev/:event_identifier/participant-cancel/:paricipant_cancel_auth_token'],
            name: 'registration.participant-cancel.root',
            meta: {
                title: 'Login',
                layout: 'enduser',
                requireAuth: false
            },
            component: () => import('@/modules/registration/views/login/cancel.vue')
        },
        {
            path: '/:event_identifier/register/loginregistration/:login_key/:pagenum',
            alias: '/dev/:event_identifier/register/loginregistration/:login_key/:pagenum',
            name: 'registration.edit-registration',
            meta: {
                title: 'Register',
                layout: 'enduser',
                requireAuth: false
            },
            component: () => import('@/modules/registration/views/register/register.vue')
        },
        {
            path: '/:event_identifier/participant-receipt-email/:participant_token',
            alias: '/dev/:event_identifier/participant-receipt-email/:participant_token',
            name: 'registration.send-receipt',
            meta: {
                title: 'Send a Receipt',
                layout: 'enduser',
                requireAuth: false
            },
            component: () => import('@/modules/registration/views/send-receipt/send-receipt.vue')
        },
        {
            path: '/:event_identifier/register/participant/changepass/:password_token',
            name: 'registration.change-password',
            meta: {
                title: 'Change Password',
                layout: 'enduser',
                requireAuth: false
            },
            component: () => import('@/modules/registration/views/change-password/change-password.vue')
        },
        {
            path: '/:event_identifier/register/:pagenum',
            alias: '/dev/:event_identifier/register/:pagenum',
            name: 'registration.register',
            meta: {
                title: 'Register',
                layout: 'enduser',
                requireAuth: false
            },
            component: () => import('@/modules/registration/views/register/register.vue')
        },
        {
            path: '/:event_identifier/stripe-return/:pagenum',
            alias: '/dev/:event_identifier/stripe-return/:pagenum',
            name: 'registration.stripe',
            meta: {
                title: 'Register',
                layout: 'enduser',
                requireAuth: false
            },
            component: () => import('@/modules/registration/views/stripe-return/stripe-return.vue')
        },
        {
            path: '/:event_identifier/register/pages/:page_uuid',
            alias: '/dev/:event_identifier/register/pages/:page_uuid',
            name: 'registration.content-pages',
            meta: {
                title: 'Content Page',
                layout: 'enduser',
                requireAuth: false
            },
            component: () => import('@/modules/registration/views/content-page/content-page.vue')
        },
        ...Container.get(LeadsModule).routes(),
        ...Container.get(SurveyModule).routes()
    ]
})
